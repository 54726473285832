var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-6" },
    [
      _vm.pageInFileUpload === 0
        ? _c("p", { staticClass: "text-h3 text-center vitrueGrey--text" }, [
            _vm._v(" " + _vm._s(_vm.$t("account.team.file.upload")) + " ")
          ])
        : _vm._e(),
      _vm.pageInFileUpload === 0
        ? _c(
            "p",
            { staticClass: "text-body-1 text-center vitrueGrey--text mt-3" },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("account.team.file.explanation")) + " "
              )
            ]
          )
        : _vm._e(),
      _c("invite-via-file", {
        attrs: { addingUsers: "", page: _vm.pageInFileUpload },
        on: {
          "can-progress-event": function($event) {
            _vm.canProgressInFileUpload = $event
          },
          "go-to-previous-page": function($event) {
            return _vm.goToPreviousPage()
          }
        },
        model: {
          value: _vm.usersToCreate,
          callback: function($$v) {
            _vm.usersToCreate = $$v
          },
          expression: "usersToCreate"
        }
      }),
      _vm.errorMessage || _vm.warningMessage
        ? _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _vm.errorMessage
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "8" } },
                    [
                      _c("alert-message", {
                        staticClass: "mb-0 mr-2",
                        attrs: { message: _vm.errorMessage, type: "error" }
                      })
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("alert-message", {
                        staticClass: "mb-0 mr-2",
                        attrs: { message: _vm.warningMessage, type: "warning" }
                      })
                    ],
                    1
                  ),
              _vm.errorMessage
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "4" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            color: "vitrueDarkGreen",
                            disabled: _vm.invitingUsers,
                            width: "100%"
                          },
                          on: { click: _vm.uploadUsers }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "vitrueDarkGreen" }
                            },
                            [_vm._v("sync")]
                          ),
                          _vm._v(
                            _vm._s(_vm.$t("account.team.file.retry")) + " "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.invitingUsers
        ? _c("v-progress-linear", {
            attrs: {
              color: "success",
              value: _vm.percentageComplete,
              rounded: "",
              height: "6"
            }
          })
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "mt-6", attrs: { justify: "center", "no-gutters": "" } },
        [
          !_vm.warningMessage
            ? _c(
                "v-col",
                { attrs: { cols: "5", md: "4", lg: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        rounded: "",
                        outlined: "",
                        width: "100%"
                      },
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { staticClass: "ml-3", attrs: { cols: "5", md: "4", lg: "3" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "", color: "transparent" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "span",
                            _vm._g({}, on),
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    rounded: "",
                                    width: "100%",
                                    disabled: !_vm.canProgressInFileUpload
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.pageInFileUpload < 2
                                        ? (_vm.pageInFileUpload += 1)
                                        : _vm.uploadUsers()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.nextButtonMessage))]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  !_vm.canProgressInFileUpload
                    ? _c("feature-flag-note", {
                        attrs: {
                          message:
                            _vm.pageInFileUpload === 0
                              ? _vm.$t("tooltips.uploadFile")
                              : _vm.$t("tooltips.selectEmailAndRole")
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }