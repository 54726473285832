<template>
  <v-card class="pa-6">
    <p
      v-if="pageInFileUpload === 0"
      class="text-h3 text-center vitrueGrey--text"
    >
      {{ $t("account.team.file.upload") }}
    </p>
    <p
      v-if="pageInFileUpload === 0"
      class="text-body-1 text-center vitrueGrey--text mt-3"
    >
      {{ $t("account.team.file.explanation") }}
    </p>
    <invite-via-file
      v-model="usersToCreate"
      addingUsers
      :page="pageInFileUpload"
      @can-progress-event="canProgressInFileUpload = $event"
      @go-to-previous-page="goToPreviousPage()"
    />
    <v-row v-if="errorMessage || warningMessage" align="center">
      <v-col cols="12" sm="8" v-if="errorMessage">
        <alert-message
          class="mb-0 mr-2"
          :message="errorMessage"
          type="error"
        ></alert-message>
      </v-col>
      <v-col cols="12" v-else>
        <alert-message
          class="mb-0 mr-2"
          :message="warningMessage"
          type="warning"
        ></alert-message>
      </v-col>
      <v-col cols="12" sm="4" v-if="errorMessage">
        <v-btn
          outlined
          color="vitrueDarkGreen"
          @click="uploadUsers"
          :disabled="invitingUsers"
          width="100%"
        >
          <v-icon color="vitrueDarkGreen" class="mr-2">sync</v-icon
          >{{ $t("account.team.file.retry") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="invitingUsers"
      color="success"
      :value="percentageComplete"
      rounded
      height="6"
    />
    <v-row class="mt-6" justify="center" no-gutters>
      <v-col cols="5" md="4" lg="3" v-if="!warningMessage">
        <v-btn color="primary" rounded outlined width="100%" @click="close()">{{
          $t("buttons.cancel")
        }}</v-btn>
      </v-col>
      <v-col cols="5" md="4" lg="3" class="ml-3">
        <v-tooltip right color="transparent">
          <feature-flag-note
            v-if="!canProgressInFileUpload"
            :message="
              pageInFileUpload === 0
                ? $t('tooltips.uploadFile')
                : $t('tooltips.selectEmailAndRole')
            "
          />
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                color="primary"
                rounded
                width="100%"
                @click="
                  pageInFileUpload < 2 ? (pageInFileUpload += 1) : uploadUsers()
                "
                :disabled="!canProgressInFileUpload"
                >{{ nextButtonMessage }}</v-btn
              ></span
            >
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import InviteViaFile from "@/components/dashboard/InviteDialog/InviteViaFile";
import FeatureFlagNote from "../common/FeatureFlagNote";
import AlertMessage from "./AlertMessage";
import { mapGetters } from "vuex";
import { createTeamMembers, addEndUsersAndUpdateTags } from "../../customApi";

export default {
  name: "AddUsersByFile",
  components: {
    InviteViaFile,
    FeatureFlagNote,
    AlertMessage
  },
  props: {
    teamMembers: Array,
    endUsers: Array
  },
  data() {
    return {
      usersToCreate: [],
      pageInFileUpload: 0,
      canProgressInFileUpload: false,
      invitingUsers: false,
      errorMessage: null,
      warningMessage: null,
      everythingTag: { name: "Everything" },
      batchSize: 20,
      usersCreatedCount: 0
    };
  },
  computed: {
    ...mapGetters(["teamMemberId"]),
    invalidRoleInMembers() {
      return !!this.usersToCreate.find(
        x =>
          x.role !== "End User" &&
          x.role !== "SuperAdmin" &&
          x.role !== "Admin" &&
          x.role !== "RestrictedAdmin"
      );
    },
    invalidMemberEmail() {
      return !!this.usersToCreate.find(x => !/.+@.+\..+/.test(x.email));
    },
    mappedTeamMembers() {
      let teamMembers = this.usersToCreate.filter(x => x.role !== "End User");
      return teamMembers.map(x => {
        let tags = this.getTagsInDTOFormat(x.tags);
        let role = x.restrictedAdmin ? "RestrictedAdmin" : x.role;
        return {
          role: role,
          userName: String(x.email),
          email: { value: String(x.email) },
          tags: this.canAssignTags(x) ? tags : [this.everythingTag],
          inviteMember: false
        };
      });
    },
    mappedEndUsers() {
      return this.usersToCreate.map(x => {
        let tags = this.getTagsInDTOFormat(x.tags);
        return {
          email: { value: String(x.email) },
          name: String(x.fullName),
          tags: x.tags ? tags : [],
          autoJoinTeam: true
        };
      });
    },
    teamMembersToUpload() {
      return this.usersCreatedCount < this.mappedTeamMembers.length
        ? this.mappedTeamMembers.slice(this.usersCreatedCount)
        : [];
    },
    endUsersToUpload() {
      return this.usersCreatedCount >= this.mappedTeamMembers.length
        ? this.mappedEndUsers.slice(
            this.usersCreatedCount - this.mappedTeamMembers.length
          )
        : this.mappedEndUsers;
    },
    percentageComplete() {
      let proportion =
        this.usersCreatedCount /
        (this.mappedTeamMembers.length + this.mappedEndUsers.length);
      return Math.round(100 * proportion);
    },
    nextButtonMessage() {
      if (this.pageInFileUpload < 2) {
        return this.$t("buttons.next");
      }
      return this.warningMessage
        ? this.$t("buttons.finish")
        : this.$t("buttons.complete");
    }
  },
  methods: {
    async uploadUsers() {
      this.validateCSV();
      if (this.errorMessage) {
        return;
      }
      if (this.warningMessage) {
        this.close();
        return;
      }
      try {
        this.invitingUsers = true;
        this.removeDuplicateUsers();
        await this.createUsersInBatches(
          createTeamMembers,
          this.teamMembersToUpload
        );
        await this.createUsersInBatches(
          addEndUsersAndUpdateTags,
          this.endUsersToUpload
        );
        if (!this.errorMessage && !this.warningMessage) {
          this.$emit("input", false);
          this.$emit("success", this.$t("account.team.file.successMessage"));
        }
      } catch (error) {
        this.usersCreatedCount += error.failedIndex ?? 0;
        this.errorMessage = this.$t("account.inviteMember.createError");
      } finally {
        this.invitingUsers = false;
      }
    },
    async createUsersInBatches(createUserFuction, users, startIndex = 0) {
      if (users.length === 0) {
        return;
      }
      let endIndex = startIndex + this.batchSize;
      let userBatch = users.slice(startIndex, endIndex);
      await createUserFuction(userBatch);
      this.usersCreatedCount += userBatch.length;
      if (endIndex < users.length) {
        await this.createUsersInBatches(createUserFuction, users, endIndex);
      }
    },
    validateCSV() {
      this.errorMessage = null;
      if (this.invalidRoleInMembers) {
        this.errorMessage = this.$t("account.team.file.incorrectRoleError");
      } else if (this.invalidMemberEmail) {
        this.errorMessage = this.$t("account.team.file.invalidEmailError");
      }
    },
    removeDuplicateUsers() {
      var duplicateUsers = this.getDuplicateUsers();
      var existingUsers = this.getExistingUsers();

      var allRepeats = [...duplicateUsers, ...existingUsers];
      var emailsToExclude = allRepeats.map(x => x.email);

      if (emailsToExclude.length > 0) {
        this.createDuplicateWarning(emailsToExclude);
      }

      this.usersToCreate = this.usersToCreate.filter(
        x => !emailsToExclude.includes(x.email)
      );
    },
    getDuplicateUsers() {
      return this.usersToCreate.filter(
        (value, index, self) =>
          index !== self.findIndex(x => x.email === value.email)
      );
    },
    getExistingUsers() {
      return this.usersToCreate.filter(x => {
        var teamMember = this.teamMembers.find(a => a.email.value === x.email);
        var endUser = this.endUsers.find(a => a.email.value === x.email);
        return !!teamMember || !!endUser;
      });
    },
    createDuplicateWarning(duplicates) {
      var warning = this.$t("account.team.file.duplicatesMessage");
      var emails = [...new Set(duplicates)].join(", ");
      warning += "<b>" + emails + "</b>";
      this.warningMessage = warning;
    },
    getTagsInDTOFormat(tags) {
      let tagsWithoutQuotes = tags ? tags.replace(/['"]+/g, "") : tags;
      return String(tagsWithoutQuotes)
        .split(",")
        .map(x => ({ name: x.trim() }));
    },
    canAssignTags(user) {
      let isAdmin = user.role === "Admin" || user.role === "RestrictedAdmin";
      return user.tags && isAdmin;
    },
    close() {
      if (this.pageInFileUpload === 2) {
        this.$emit("refresh");
      }
      this.$emit("input", false);
    },
    goToPreviousPage() {
      this.errorMessage = null;
      this.usersCreatedCount = 0;
      this.pageInFileUpload -= 1;
    }
  }
};
</script>
